<!--
 * @Description: 政策咨询
 * @Author: LXG
 * @Date: 2021-08-19
 * @Editors: LXG
 * @LastEditTime: 2021-08-19
-->
<template>
    <div class="policy-advisory">
        <div class="banner">
            <div class="bannerImg"></div>
        </div>
        <header class="wrap-header">
            <h3>政策咨询</h3>
        </header>
    </div>
</template>

<script>
export default {
    name: 'PolicyAdvisory',
    components: {},
    filters: {},
    props: {},
    data() {
        return {

        };
    },
    computed: {},
    methods: {},
    watch: {},
    created() { },
    mounted() { },
}
</script>

<style lang="scss" scoped>
$width: 1150px;

.policy-advisory {
    width: 100%;
    min-height: 800px;
    background: url("~@/assets/images/background.png") no-repeat;
    padding-bottom: 10px;
    background-size: 100% 1070px;
}
.banner {
    width: $width;
    height: 254px;
    padding-right: 5rem;
    padding-left: 5rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    align-items: center;

    .bannerInfo {
        min-width: 664px;
        height: 105px;
        background: url("~@/assets/images/policyServiceBanner.png") no-repeat;
    }
    .bannerImg {
        min-width: 423px;
        height: 254px;
        background: url("~@/assets/images/bannerImg.png") no-repeat;
        background-size: contain;
    }
}
.wrap-header {
    width: $width;
    height: 80px;
    margin: 0 auto;
    line-height: 80px;
    text-align: center;
    color: $col-theme;
    background-color: #ffffff;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
}
</style>